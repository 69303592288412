import {
  IonContent,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonList,
  IonText,
} from "@ionic/react";
import { imagesOutline, helpOutline } from "ionicons/icons";
import Image from "next/image";
import Link from "next/link";
import React, { useContext } from "react";
import { YearVersionSelector } from "../components/YearVersionSelector";
import flagInHandImage from "../images/flag-in-hand.jpg";
import { VersionYearContext } from "../state/VersionYearContext";

const Home: React.FC = () => {
  const { versionYear } = useContext(VersionYearContext);
  return (
    <IonContent className="ion-padding">
      <Image src={flagInHandImage} alt="a person holding USA flag." />
      <h2 style={{ fontSize: "0.9rem" }}>
        US Interview Questions and Flashcards
      </h2>
      <YearVersionSelector />
      <IonItemDivider />
      <IonList>
        {versionYear === "2008" ? (
          <>
            <Link href={"2008/questions"}>
              <IonItem
                href={"2008/questions"}
                color="primary"
                lines="none"
                detail={false}
              >
                <IonIcon slot="start" icon={helpOutline} />
                <IonText>2008 Questions</IonText>
              </IonItem>
            </Link>
            <IonItemDivider />
            <Link href={"2008/flashcards"}>
              <IonItem
                href={"2008/flashcards"}
                color="primary"
                lines="none"
                detail={false}
              >
                <IonIcon slot="start" icon={imagesOutline} />
                <IonText>2008 Flash Cards</IonText>
              </IonItem>
            </Link>
          </>
        ) : (
          <>
            <Link href={"2020/questions"}>
              <IonItem
                href={"2020/questions"}
                color="primary"
                lines="none"
                detail={false}
              >
                <IonIcon slot="start" icon={helpOutline} />
                <IonText>2020 Questions</IonText>
              </IonItem>
            </Link>
            <IonItemDivider />
            <Link href={"2020/flashcards"}>
              <IonItem
                href={"2020/flashcards"}
                color="primary"
                lines="none"
                detail={false}
              >
                <IonIcon slot="start" icon={imagesOutline} />
                <IonText>2020 Flash Cards</IonText>
              </IonItem>
            </Link>
          </>
        )}
      </IonList>
    </IonContent>
  );
};

export default Home;
