import {
  IonItemDivider,
  IonLabel as IonTitle,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import React, { useContext } from "react";
import { VersionYearContext } from "../state/VersionYearContext";

export const YearVersionSelector: React.FC = () => {
  const { versionYear, versionYearSet } = useContext(VersionYearContext);

  return (
    <>
      <IonItemDivider>
        <IonTitle>Select Interview version</IonTitle>
      </IonItemDivider>
      <IonSegment
        color="success"
        value={versionYear}
        onIonChange={(e) => {
          if (e.detail.value === "2008") {
            versionYearSet("2008");
          } else {
            versionYearSet("2020");
          }
        }}
      >
        <IonSegmentButton value="2008">
          <IonTitle>2008-2019</IonTitle>
        </IonSegmentButton>
        <IonSegmentButton value="2020">
          <IonTitle>2020-Current</IonTitle>
        </IonSegmentButton>
      </IonSegment>
    </>
  );
};
